import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
        This <code>site is </code>under construction.
        </p>
        <a
          className="App-link"
          href="#"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </header>
    </div>
  );
}

export default App;
